import React from "react";
import Rd2img1 from "../Images/Rd2img1.png";
import Reliable from "../Images/reliable.png";
import Profit from "../Images/profit.png"
import Secure from "../Images/secure.png"
import Cloud from "../Images/cloud.png"
import Integration from "../Images/integration.png"
import Customer from "../Images/customer.png"
import Devops from "../Images/devops.png"

const Readmore2 = () => {
  // card data
  const cardList = [
    {
      img: Rd2img1,
      title: "Tailored Solutions for Software Operation",
      desc: "Offering customized software operation delivery services to meet the specific needs of each client.",
    },
    {
      img: Profit,
      title: "Scalability",
      desc: "Providing solutions that can grow and adapt alongside clients' businesses, ensuring long-term viability.",
    },
    {
        img: Cloud,
        title: "Expertise in Cloud Services",
        desc: "Specializing in cloud-based solutions, including setup, migration, and optimization for enhanced performance.",
    },
    {
        img: Devops,
        title: "Dev-Ops Adoption",
        desc: "Employing agile, Dev-Ops practices to deliver projects efficiently, adapt to changing requirements, and foster collaboration.",
    },

  ];

  const cardList2 = [
    {
      img: Reliable,
      title: "Reliability and Stability",
      desc: "Ensuring a robust foundation for clients' operations, focusing on stability and reliability.",
    },
    {
        img: Secure,
        title: "Security Measures",
        desc: "Implementing stringent security protocols to safeguard clients' data and systems against potential threats.",
    },
    {
        img: Integration,
        title: "Seamless Integration",
        desc: "Integrating new software solutions seamlessly with existing systems and workflows to minimize disruption.",
    },
    {
        img: Customer,
        title: "Client-Centric Approach",
        desc: "Placing clients' needs and satisfaction at the forefront, with a commitment to delivering value and exceeding expectations.",
    },
  ];

  return (
    <>
    <div>
    <section className="bg-gradient-to-r from-blue-300 to-indigo-900 py-8 px-4 mx-auto mt-10 max-w-screen-xl 
    text-center lg:py-16 lg:px-12 rounded-3xl">
        <div className="text-white">
          <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl lg:text-5xl
          object-cover transition-transform duration-500 ease-in-out transform hover:scale-110">
          Elevate Your Efficiency: Unleashing Seamless Software Operations for Unrivaled Performance!
          </h1>
          <p className="mb-8 text-lg font-normal lg:text-xl">
          Offering customized software operation delivery services to meet the specific needs of each client.
        Where Efficiency Meets Excellence: Empowering Businesses through Superior Software Operations.
        We're dedicated to optimizing your systems, ensuring unparalleled performance and driving success to new heights.
          </p>
        </div>
      </section>
      </div>

    <section className="container mx-auto flex justify-center gap-2 pb-[10rem] items-center">
      <div className="about-cards flex gap-10 flex-col md:flex-row px-3">
        {/* CARDS */}
        <div>
          {cardList.map((card, id) => (
            <div
              key={id}
              className="flex flex-col cursor-pointer bg-white justify-center py-5 px-6 text-center 
                         items-center mt-12 rounded-tl-[35px] rounded-br-[35px] shadow-green-300 md:min-h-[320px] 
                         w-full card-item-div max-w-screen-sm min-h-[250px]">
              <img src={card.img} alt="card-image" className="w-[75px] mb-4" />
              <p className="text-[24px] font-bold uppercase mb-7">
                {card.title}
              </p>
              <p className="text-[22px] font-medium leading-2 w-full">
                {card.desc}
              </p>
            </div>
          ))}
        </div>
        
        
        <div>
          {cardList2.map((card, id) => (
            <div
              key={id}
              className="flex flex-col cursor-pointer bg-white justify-center py-5 px-6 text-center 
                         items-center mt-12 rounded-tl-[35px] rounded-br-[35px] shadow-green-300 md:min-h-[320px] 
                         w-full card-item-div max-w-screen-sm min-h-[250px]">
              <img src={card.img} alt="card-image" className="w-[75px] mb-4" />
              <p className="text-[25px] font-bold uppercase mb-7">
                {card.title}
              </p>
              <p className="text-[22px] font-medium leading-2 w-full">
                {card.desc}
              </p>
            </div>
          ))}
        </div>
      
      
      </div>
    </section>
    </>
  );
};

export default Readmore2;
