import React from "react";
import Rd31 from "../Images/Rd31.jpg";
import Rs322 from "../Images/Rs322.jpeg";
import Rd331 from "../Images/Rd331.jpeg";
import SecurityMeasures from "..//Images/Rd3_securityMeasures.jpg";
import AdvTech from "../Images/Rd3_AdvTech.avif";
import QAfocus from "../Images/Rd3_QAfocus.avif";
import ContImprove from "../Images/Rd3_ContImprove.jpg";
import Client from "../Images/Rd3_Client.webp";
import ContTest from "../Images/Rd3_ConTesting.jpg";

function Readmore31() {
  return (
    <>
      <div>
        <section className="bg-gradient-to-r from-blue-300 to-indigo-900 py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <div className="text-white">
            <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl lg:text-5xl">
              Unleashing the Power of Reliability: Elevate Your Software's
              Performance with Our Testing Solutions.
            </h1>
            <p className="mb-8 text-lg font-normal lg:text-xl">
              Step into a world of bug-free brilliance as we empower your
              software with our advanced testing solutions. Experience
              unparalleled reliability as you elevate your software's
              performance with our cutting-edge testing solutions. Our robust
              tools and methodologies ensure a seamless and bug-free user
              experience, empowering your software to achieve new heights of
              efficiency and dependability.
            </p>
          </div>
        </section>
      </div>

      <div className="max-w-screen-xl mx-2 lg:mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3 my-10">
        {/* Card Example 1 */}
        {/* <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
          <h2 className="font-bold text-2xl text-center p-5">Comprehensive Testing Solutions</h2>

            <img
              src={Rd31}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white p-4 translate-y-full group-hover:translate-y-0 transition-transform duration-300">
              <p className="text-center mt-2 text-xl">
                Offering end-to-end testing services covering all stages of the software development lifecycle.
              </p>
            </div>
          </div>
        </div> */}

        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            {/* Keep the heading fixed at the top */}
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white">
              Comprehensive Testing Solutions
            </h2>

            {/* Image stays the same */}
            <img
              src={Rd31}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />

            {/* Paragraph text starts off-screen and moves from top on hover */}
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                Offering end-to-end testing services covering all stages of the
                software development lifecycle.
              </p>
            </div>
          </div>
        </div>

        {/* Card Example 2 */}
        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white">
              Diverse QA Expertise
            </h2>
            <img
              src={Rs322}
              alt="img2"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                Specializing in testing across various domains, including
                functional, performance, security, and compatibility testing.
              </p>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white ">
              Scalability
            </h2>
            <img
              src={Rd331}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                Providing solutions that can grow and adapt alongside clients'
                businesses, ensuring long-term viability.
              </p>
            </div>
          </div>
        </div>

        {/* Card 4 */}
        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white">
              Security Measures
            </h2>
            <img
              src={SecurityMeasures}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                Implementing stringent security protocols to safeguard clients'
                data and systems against potential threats.
              </p>
            </div>
          </div>
        </div>

        {/* Card 5 */}
        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white">
              Advanced Tools & Technologies
            </h2>
            <img
              src={AdvTech}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                Utilizing cutting-edge testing tools and methodologies to ensure
                thorough and efficient testing processes.
              </p>
            </div>
          </div>
        </div>

        {/* Card 6 */}
        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white">
              Quality Assurance Focus
            </h2>
            <img
              src={QAfocus}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                Placing a strong emphasis on quality assurance to identify and
                resolve defects early in the development cycle.
              </p>
            </div>
          </div>
        </div>

        {/* Card 7 */}
        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white">
              Continuous Improvement
            </h2>
            <img
              src={ContImprove}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                Committing to ongoing learning and improvement to stay abreast
                of industry best practices and emerging trends.
              </p>
            </div>
          </div>
        </div>

        {/* Card 8 */}
        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white">
              Client Satisfaction
            </h2>
            <img
              src={Client}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                Prioritizing client satisfaction by delivering high-quality
                testing services that meet or exceed expectations, on time and
                within budget.
              </p>
            </div>
          </div>
        </div>

        {/* Card 9 */}
        <div className="shadow-lg rounded-3xl bg-white group overflow-hidden relative h-80 border-slate-400 border-2">
          <div className="relative h-full overflow-hidden">
            <h2 className="font-bold text-2xl text-center p-5 z-10 bg-white">
              Continous Testing
            </h2>
            <img
              src={ContTest}
              alt="img1"
              className="w-full h-full object-cover opacity-100 group-hover:opacity-90 transition-opacity duration-300"
            />
            <div className="absolute top-[calc(100%)] left-0 right-0 bg-black bg-opacity-60 text-white p-4 group-hover:top-[70px] transition-all duration-300">
              <p className="text-center mt-2 text-xl">
                In DevOps, quality is paramount. Our expert QA team ensures
                continuous testing throughout the entire SDLC, maintaining high
                standards and seamless integration with development processes.
              </p>
            </div>
          </div>
        </div>
        {/* Repeat similar card structure */}
        {/* Additional cards follow the same pattern */}
      </div>
    </>
  );
}

export default Readmore31;
