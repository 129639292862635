import React from 'react'
import Profile from '../Componant/Profile'
import Hero_Home from '../Componant/Hero_Home.js'
export default function HOME() {
  return (
    <div className='mb-5 bg-white'>
      <Hero_Home/>
    </div>
    
  )
}
