
import React from "react";
import Sirimg from "../Images/PrakashSir_img2.png";
import Sir2img from "../Images/RahulHoleSir.png";

const Profile = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-center items-start min-h-screen px-10 pt-16 space-y-32 lg:space-y-0 lg:space-x-8">

      {/* ---------------------------------------------------------First card------------------------------------------------------------------ */}
      
      <div className="relative bg-gradient-to-r from-green-400 to-blue-500 p-1 rounded-3xl shadow-lg ">
        {/* Card Content Container */}
        <div className="bg-gray-900 rounded-3xl text-center pt-16 pb-10 px-8 text-white relative z-10 max-w-[750px]">
          {/* Image that overlaps the border */}
          <img
            src={Sirimg}
            alt="Prakash Pawar"
            className="mx-auto mb-8 w-48 h-48 rounded-full object-cover transform transition-transform duration-300 hover:scale-105 
                       relative top-[-157px]"
          />

          {/* Main Content */}
          <h1 className="text-4xl font-bold mt-[-10rem]">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
              Prakash Pawar
            </span>
          </h1>
          <h5 className="text-2xl font-bold pt-4 pb-2">Founder & Director</h5>
          <p className="mt-4 mb-5 text-lg text-gray-300">
            With 19 years of extensive experience in IT project management, I have led successful transformations across a broad range of methodologies,
            including Waterfall, Agile, and DevOps. My expertise lies in driving innovation and efficiency through strategic IT solutions tailored to meet diverse business needs.
            By maintaining a strong focus on customer-centric approaches, I have consistently delivered results that ensure operational excellence, scalability,
            and sustained success across multiple domains. My commitment to continuous improvement and
            innovation has been a cornerstone in helping organizations achieve their IT and business seamlessly.
          </p>
          {/* Button */}
          <div className="mt-10 space-x-4">
            <a href="https://www.linkedin.com/in/prakash-pawar-6b709917/" target="_blank" rel="noopener noreferrer">
              <button className="bg-gradient-to-r from-green-400 to-blue-500 text-white transform transition-transform duration-300 hover:scale-105 px-4 py-2 rounded-full">
                KNOW MORE
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------Second Card----------------------------------------------------------------- */}

      <div className="relative bg-gradient-to-r from-green-400 to-blue-500 p-1 rounded-3xl shadow-lg">
        {/* Card Content Container */}
        <div className="bg-gray-900 rounded-3xl text-center pt-16 pb-10 px-8 text-white relative z-10 max-w-[750px]">
          {/* Image that overlaps the border */}
          <img
            src={Sir2img}
            alt="Rahul Hole"
            className="mx-auto mb-8 w-48 h-48 rounded-full object-cover transform transition-transform duration-300 hover:scale-105 
                       relative top-[-157px]"
          />

          {/* Main Content */}
          <h1 className="text-4xl font-bold mt-[-10rem]">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
              Rahul Hole
            </span>
          </h1>
          <h5 className="text-2xl font-bold pt-4 pb-2">Chief Technology Development Consultant</h5>
          <p className="mt-4 mb-5 text-lg text-gray-300">
            With over 19 years of experience as a Chief Technology Development Consultant, I specialize in Java, C++, C#, and the .NET Framework. 
            My focus is on developing low-latency, distributed systems for the equities market data domain, ensuring optimal performance, reliability, 
            and security. I have successfully led large-scale projects, building intuitive software architectures tailored to client needs, 
            while maintaining robust data security. As a certified Scrum Master and Six Sigma Green Belt, I promote a culture of collaboration, innovation, 
            and continuous improvement, driving significant business outcomes for stakeholders through strategic leadership.
          </p>
          {/* Button */}
          <div className="mt-10 space-x-4">
            <a href="https://www.linkedin.com/in/rahul-hole-9b01046/" target="_blank" rel="noopener noreferrer">
              <button className="bg-gradient-to-r from-green-400 to-blue-500 text-white transform transition-transform duration-300 hover:scale-105 px-4 py-2 rounded-full">
                KNOW MORE
              </button>
            </a>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Profile;


// ++++++++++++++++++++++++++++++++++++++++space on vertical angle so that cards will not overlap+++++++++++++++++++++++++++++++++++++++++++++

// import React from "react";
// import Sirimg from "../Images/PrakashSir.jpeg";
// import Sir2img from "../Images/RahulHoleSir.png";

// const Profile = () => {
//   return (
//     <div className="flex flex-col lg:flex-row justify-center items-center min-h-screen px-10 pt-16 space-y-12 lg:space-y-0 lg:space-x-8">

//       {/* ---------------------------------------------------------First card------------------------------------------------------------------ */}
      
//       <div className="relative bg-gradient-to-r from-green-400 to-blue-500 p-1 rounded-3xl shadow-lg">
//         {/* Card Content Container */}
//         <div className="bg-gray-900 rounded-3xl text-center pt-16 pb-10 px-8 text-white relative z-10 max-w-[750px]">
//           {/* Image that overlaps the border */}
//           <img
//             src={Sirimg}
//             alt="Prakash Pawar"
//             className="mx-auto mb-8 w-48 h-48 rounded-full object-cover transform transition-transform duration-300 hover:scale-105 
//                        relative top-[-157px]"
//           />

//           {/* Main Content */}
//           <h1 className="text-4xl font-bold mt-[-10rem]">
//             <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
//               Prakash Pawar
//             </span>
//           </h1>
//           <h5 className="text-2xl font-bold pt-4 pb-2">Founder & Director</h5>
//           <p className="mt-4 mb-5 text-lg text-gray-300">
//             With 18 years of extensive experience in IT project management, I have led successful transformations across a broad range of methodologies,
//             including Waterfall, Agile, and DevOps. My expertise lies in driving innovation and efficiency through strategic IT solutions tailored to meet diverse business needs.
//             By maintaining a strong focus on customer-centric approaches, I have consistently delivered results that ensure operational excellence, scalability,
//             and sustained success across multiple domains. My commitment to continuous improvement and
//             innovation has been a cornerstone in helping organizations achieve their IT and business objectives seamlessly.
//           </p>
//           {/* Button */}
//           <div className="mt-10 space-x-4">
//             <a href="https://www.linkedin.com/in/prakash-pawar-6b709917/" target="_blank" rel="noopener noreferrer">
//               <button className="bg-gradient-to-r from-green-400 to-blue-500 text-white transform transition-transform duration-300 hover:scale-105 px-4 py-2 rounded-full">
//                 KNOW MORE
//               </button>
//             </a>
//           </div>
//         </div>
//       </div>

//       {/* ------------------------------------------------------Second Card----------------------------------------------------------------- */}

//       <div className="relative bg-gradient-to-r from-green-400 to-blue-500 p-1 rounded-3xl shadow-lg">
//         {/* Card Content Container */}
//         <div className="bg-gray-900 rounded-3xl text-center pt-16 pb-10 px-8 text-white relative z-10 max-w-[750px]">
//           {/* Image that overlaps the border */}
//           <img
//             src={Sir2img}
//             alt="Rahul Hole"
//             className="mx-auto mb-8 w-48 h-48 rounded-full object-cover transform transition-transform duration-300 hover:scale-105 
//                        relative top-[-157px]"
//           />

//           {/* Main Content */}
//           <h1 className="text-4xl font-bold mt-[-10rem]">
//             <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
//               Rahul Hole
//             </span>
//           </h1>
//           <h5 className="text-2xl font-bold pt-4 pb-2">Chief Technology Development Consultant</h5>
//           <p className="mt-4 mb-5 text-lg text-gray-300">
//             With over 19 years of experience as a Chief Technology Development Consultant, I specialize in Java, C++, C#, and the .NET Framework. 
//             My focus is on developing low-latency, distributed systems for the equities market data domain, ensuring optimal performance, reliability, 
//             and security. I have successfully led large-scale projects, building intuitive software architectures tailored to client needs, 
//             while maintaining robust data security. As a certified Scrum Master and Six Sigma Green Belt, I promote a culture of collaboration, innovation, 
//             and continuous improvement, driving significant business outcomes for stakeholders through strategic leadership.
//           </p>
//           {/* Button */}
//           <div className="mt-10 space-x-4">
//             <a href="https://www.linkedin.com/in/rahul-hole-9b01046/" target="_blank" rel="noopener noreferrer">
//               <button className="bg-gradient-to-r from-green-400 to-blue-500 text-white transform transition-transform duration-300 hover:scale-105 px-4 py-2 rounded-full">
//                 KNOW MORE
//               </button>
//             </a>
//           </div>
//         </div>
//       </div>

//     </div>
//   );
// };

// export default Profile;
