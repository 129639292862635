import { BrowserRouter, Routes,Route,Link, HashRouter } from 'react-router-dom';
import Contact from './Pages/CONTACT.js';
import ABOUT from './Pages/ABOUT.js';
import HOME from './Pages/HOME.js';
import SERVICES from './Pages/SERVICES.js';
import NAVBAR from './Componant/NAVBAR.js';
import Footer from './Componant/Footer.js';
// import COSTOM from './Pages/COSTOM.js';
// import SoftOpt from './Pages/Softopt.js';
// import Sfttest from './Pages/Sfttest.js';
import ScrollToTop from './Componant/ScrollToTop.js';
import PrivacyPolicy from './Pages/PrivacyPolicy.js';
import TermsAndConditions from './Pages/Terms.js';
import Readmore1 from './Pages/Readmore1.js';
import Readmore2 from './Pages/Readmore2.js';
// import Readmore3 from './Pages/Readmore3.js';
import Readmore31 from './Pages/Readmore31.js';
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
// import Cliental from './Pages/Cliental.js';

// Keyframes for the pulse animation
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// Styled component for the loading container
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black; // Uncomment if you want a black background
`;

// Styled component for the loading image with size adjustment
const LoadingImage = styled.img`
  animation: ${pulse} 2.5s infinite;
  width: 400px;  // Set the desired width
  height: 250px; // Set the desired height
`;

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Change the delay time as needed

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
   <>
   


   <div className="App">
      {loading ? (
        <LoadingContainer>
          <LoadingImage src={require("./Componant/ConsulTech Logo.png")} alt="Loading..." />
        </LoadingContainer>
      ) : (
        <HashRouter>
   <ScrollToTop/>
   <NAVBAR/>
   <Routes>
    <Route path='/' element={<HOME/>} />
    <Route path='/Services' element={<SERVICES/>} />
    {/* <Route path='/Cliental' element={<Cliental/>} /> */}

    <Route path='/About' element={<ABOUT/>} />
    <Route path='/Contact' element={<Contact/>} />
    {/* <Route path='/Costom' element={<COSTOM/>} /> */}
    <Route path='/Readmore1' element={<Readmore1/>} />
    <Route path='/Readmore2' element={<Readmore2/>} />
    {/* <Route path='/Readmore3' element={<Readmore3/>} /> */}
    <Route path='/Readmore31' element={<Readmore31/>} />

    {/* <Route path='/softopt' element={<SoftOpt />}></Route> */}
    {/* <Route path='/sfttest' element={<Sfttest />}></Route> */}
    <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>}></Route>
    <Route path='/TermsandConditions' element={<TermsAndConditions/>}></Route>
   </Routes>
   <Footer/>
   </HashRouter>
      )}
    </div>
  
   </>
  
  );
}

export default App;
