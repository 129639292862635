import { useState } from "react";
import { post } from "./../util/api";

function Form_Axios() {
  const [errors, setErrors] = useState("");
  const [status, setStatus] = useState(0);
  const initialData = { name: "", company_name: "", mobile: "", email: "", queries: "" };
  const [inputData, setInputData] = useState(initialData);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleData = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
    setFieldErrors({ ...fieldErrors, [e.target.name]: "" }); // Clear field error on input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let newFieldErrors = {};

    // Validation
    if (!inputData.name) newFieldErrors.name = "Name cannot be left blank!";
    if (!inputData.company_name) newFieldErrors.company_name = "Company Name cannot be left blank!";
    if (!inputData.mobile || inputData.mobile.length !== 10 || isNaN(inputData.mobile))
      newFieldErrors.mobile = "Please enter a valid 10-digit Mobile Number!";
    if (!inputData.email) newFieldErrors.email = "Email cannot be left blank!";
    if (inputData.email && (!inputData.email.includes("@") || !inputData.email.includes(".")))
      newFieldErrors.email = "Please enter a valid Email ID!";

    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      // setInputData(initialData); // Clear fields even when errors occur
      setIsLoading(false);
      return;
    }

    try {
      const response = await post("/contact_us.php", inputData);

      if (response) {
        setErrors("Form submitted successfully!");
        setStatus(1);
        setInputData(initialData); // Clear fields regardless of the result
      
      }
    } catch (error) {
      setErrors("Problem in submitting the form, Please try again later");
      setStatus(2);
    } finally {
      setIsLoading(false);
    }

    // Reset the status after a delay
    setTimeout(() => {
      setStatus(0);
      setErrors("");
    }, 5000);
  };

  return (
    <>
      <section className="bg-transparent">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <h1 className="text-gray-300 font-bold text-3xl mb-4">Mission: </h1>
              <p className="max-w-xl text-lg text-gray-400">
                Our mission is to empower businesses by providing cutting-edge technology solutions that enable them to
                unlock their full potential in the digital era.
              </p>
              <div className="mt-8">
                <a href="#" className="text-2xl font-bold text-[#78F9FD]">
                  customer.care@tipic.co.in
                </a>
                <h1 className="text-gray-300 font-bold text-1xl mt-4 mb-2">Address: </h1>
                <address className="mt-2 not-italic text-gray-400">
                  Tipic ConsulTech Pvt. Ltd., Office No.18 - Level 2, Konark Business Park, Keshavnagar, Pune - 411036
                </address>
              </div>
            </div>

            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
              {status !== 0 && (
                <div
                  className={`bg-transparent ${
                    status === 1 ? "text-green-700 border-green-500" : "text-red-700 border-red-500"
                  } border-l-4 p-4 mb-6`}
                  role="alert"
                >
                  <p>{errors}</p>
                </div>
              )}
              <form action="#" className="space-y-4" onSubmit={handleSubmit}>
                <div className="flex">
                  <input
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Enter Your Name"
                    type="text"
                    name="name"
                    value={inputData.name}
                    onChange={handleData}
                  />
                  {fieldErrors.name && <p className="text-red-600 text-sm ml-2">{fieldErrors.name}</p>}
                </div>

                <div className="flex">
                  <input
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Enter Company Name"
                    type="text"
                    name="company_name"
                    value={inputData.company_name}
                    onChange={handleData}
                  />
                  {fieldErrors.company_name && <p className="text-red-600 text-sm ml-2">{fieldErrors.company_name}</p>}
                </div>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div className="mr-5 flex">
                    <input
                      className="w-full rounded-lg border-gray-200 p-3 text-sm"
                      placeholder="Email address"
                      type="email"
                      name="email"
                      value={inputData.email}
                      onChange={handleData}
                    />
                    {fieldErrors.email && <p className="text-red-600 text-sm ml-2">{fieldErrors.email}</p>}
                  </div>

                  <div className="flex">
                    <input
                      className="w-full rounded-lg border-gray-200 p-3 text-sm"
                      placeholder="Phone Number"
                      type="tel"
                      name="mobile"
                      value={inputData.mobile}
                      onChange={handleData}
                    />
                    {fieldErrors.mobile && <p className="text-red-600 text-sm ml-2">{fieldErrors.mobile}</p>}
                  </div>
                </div>

                <div>
                  <textarea
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Message"
                    rows="2"
                    name="queries"
                    value={inputData.queries}
                    onChange={handleData}
                  ></textarea>
                </div>

                <div className="mt-4 flex">
                  <button
                    type="submit"
                    className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white hover:bg-black hover:text-white sm:w-auto"
                    disabled={isLoading}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Form_Axios;
