import React from 'react'
// import Service_card from '../Componant/Service_card'
import Soft_dev from '../Componant/Soft_dev'


export default function SERVICES() {
  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        <Soft_dev />
      </div>
    </>
  );
}
