import React from 'react'
import Form_Axios from '../Componant/form_axios' 
export default function Contact() {
  return (
  <div>
  <Form_Axios/>
  </div>
      


  )
}

// import React from 'react'
// import Form from '../Componant/Form'

// const CONTACT = () => {
//   return (
//     <div>
//       <Form/>
//     </div>
//   )
// }

// export default CONTACT