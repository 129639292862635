import React from 'react'
// import {Card, CardHeader, CardBody, Typography} from "@material-tailwind/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from 'react';

function Readmore1() {
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll:4,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 12000, // Time in milliseconds between slides
  });

  useEffect(() => {
    const updateSettings = () => {
      if (window.innerWidth < 640) { // Tailwind's 'sm' breakpoint
        setSettings({
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true, // Enable autoplay
          autoplaySpeed: 12000, // Time in milliseconds between slides
        });
      } else if (window.innerWidth < 768) { // Tailwind's 'md' breakpoint
        setSettings({
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true, // Enable autoplay
          autoplaySpeed: 12000, // Time in milliseconds between slides
        });
      } else {
        setSettings({
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 4,
          autoplay: true, // Enable autoplay
          autoplaySpeed: 12000, // Time in milliseconds between slides
        });
      }
    };

    updateSettings(); // Set initial settings
    window.addEventListener('resize', updateSettings); // Update settings on resize

    return () => {
      window.removeEventListener('resize', updateSettings); // Cleanup listener
    };
  }, []);

  
  return (
    <>
    <section className="bg-gradient-to-r from-blue-300 to-indigo-900 py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12 rounded-tr-3xl rounded-bl-3xl">
        <div className="text-white">
          <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl lg:text-5xl">
            Discover the difference that custom software development can make for your business.
          </h1>
          <p className="mb-8 text-lg font-normal lg:text-xl">
            Prepare your business for growth with our scalable solutions. We design and develop software that can evolve alongside your business, accommodating increased data, users, and functionality without compromising performance.
          </p>
        </div>
      </section>

    {/* ---------------------------------DATA CARDS------------------------------------ */}

    <div className='w-3/4 m-auto pb-[80px]'>
      <div className='mt-20'>
      <Slider {...settings}>
        {data.map((d) => (
          <div className='bg-white h-[415px] text-black rounded-xl'>
            <div className='h-56 rounded-t-xl bg-white flex justify-center items-center '>
              <img src={d.img} alt="sd" className='h-44 w-44 rounded-full shadow-xl 
              object-cover transition-transform duration-300 ease-in-out transform hover:scale-110'/>
            </div>
            
            <div className='flex flex-col justify-center items-center gap-4 px-3 pt-3 text-center text-xl'>
              <p>{d.description}</p>
            </div>
          </div>
        )
        )}
        </Slider>
      </div>
    </div>

      
    </>
  )
}

const data = [
  {
    img:require('../Images/Rd_img1.jpg'),
    description: (<><strong>Tailored software development</strong> for small and medium enterprises and retail customers.</>),
  },
  {
    img:require('../Images/Rd_img2.jpg'),
    description: (<><strong>Customized solutions</strong> to meet specific existing or new business needs.</>),
  },
  {
    img:require('../Images/Rd_img3.jpg'),
    description: (<><strong>Integration of Advance BI (business intelligence)</strong> for strategic and informed decision-making.</>),
  },
  {
    img:require('../Images/Rd_img44.jpg'),
    description: (<>Focusing on increasing sales, revenue, and profitability through <strong>data-driven insights</strong>.</>),
  },
  {
    img:require('../Images/Rd_img5.jpg'),
    description: (<><strong>Customizable development</strong> to address unique business requirements.</>),
  },
  {
    img:require('../Images/Rd_img8.jpg'),
    // old description
    description: (<>Use of customized development methodology like <strong>Waterfall Iterative, Agile or Dev-Ops</strong> as per business nature and need.</>),
    // New changes in description.
    // description: "Use customized development methodologies like Waterfall, Iterative, Agile, or DevOps based on business needs.",
  },
  {
    img:require('../Images/Rd_img6.jpg'),
    description: (<><strong>Seamless integration</strong> with existing systems for enhanced efficiency and productivity.</>),
  },
  {
    img:require('../Images/Rd_img7.jpg'),
    description: (<><strong>Continuous support</strong> and updates to ensure long-term and sustaining success.</>),
  },
  {
    img:require('../Images/Rd_img9.jpg'),
    description: (<>User-friendly interfaces designed for <strong>intuitive navigation</strong>.</>),
  },
  {
    img:require('../Images/Rd_img10.jpg'),
    description: (<><strong>Robust security measures</strong> to safeguard sensitive data.</>),
  },
  {
    img:require('../Images/Rd_img11.jpg'),
    description: (<><strong>Scalable solutions</strong> adaptable to business growth and evolving needs.</>),
  },
]

export default Readmore1;